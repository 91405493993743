import router from './router'
import store from './store'
const name = process.env.VUE_APP_TITLE || '771771威尼斯.Cm' // 网页标题
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = name + ' - ' + to.meta.title
  }
  next()
})


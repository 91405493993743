import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: "",
    component: () => import("@/layout"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home"),
        meta: {
          title: "首页"
        }
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import("@/views/aboutUs"),
        meta: {
          title: "走进熙重"
        }
      },
      {
        path: "/productList",
        name: "productList",
        component: () => import("@/views/product/productList"),
        meta: {
          title: "产品服务"
        },
        children: [
          {
            path: "product",
            name: "product",
            component: () => import("@/views/product/product"),
            meta: {
              title: "产品服务",
              hideParentTemplate: true,
            }
          },
        ]
      },
      {
        path: "/programmeList",
        name: "programmeList",
        component: () => import("@/views/programme/programmeList"),
        meta: {
          title: "解决方案"
        },
        children: [
          {
            path: "programme",
            name: "programme",
            component: () => import("@/views/programme/programme"),
            meta: {
              title: "解决方案",
              hideParentTemplate: true,
            }
          },
        ]
      },
      {
        path: "/newsList",
        name: "newsList",
        component: () => import("@/views/news/newsList"),
        meta: {
          title: "新闻列表"
        },
        children: [
          {
            path: "newsMore",
            name: "newsMore",
            component: () => import("@/views/news/newsMore"),
            meta: {
              title: "更多新闻",
              hideParentTemplate: true,
            }
          },
          {
            path: "news",
            name: "news",
            component: () => import("@/views/news/news"),
            meta: {
              title: "新闻详情",
              hideParentTemplate: true,
            }
          }
        ]
      },
      {
        path: "/joinUs",
        name: "joinUs",
        component: () => import("@/views/joinUs"),
        meta: {
          title: "加入我们"
        }
      },
      {
        path: "/contactUs",
        name: "contactUs",
        component: () => import("@/views/contactUs"),
        meta: {
          title: "联系我们"
        }
      },
    ]
  }
]


const createRouter = () => new Router({
  mode: 'history', // require service support
  // scrollBehavior: () => ({ y: 0 }),
  scrollBehavior(to, from,savedPosition) {
  //if判断可加可不加、根据自己需求
  //savedPosition当且仅当通过浏览器的前进/后退按钮触发时才可用
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0
    }
  },
  routes: constantRoutes
})

const router = createRouter()

export default router

<template>
  <div id="app">
    <KeepAlive include="Home">
      <router-view />
    </KeepAlive>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
body {
  width: 100vw;
}
html {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
